<script setup lang="ts">
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import DataTable, { type DataTablePageEvent } from 'primevue/datatable';
    import Column from 'primevue/column';
    import { useAsyncTask } from 'vue-concurrency';
    import { useLeadAction, useLeadQuery } from '../composables/leads';
    import { useDateFormatter } from '@/composables/date-format';
    import { parseISO } from 'date-fns';
    import { useI18n } from 'vue-i18n';
    import LeadChip from '@/account/components/LeadChip.vue';
    import { FetchPaginatedDataDto, LeadStateFilter, PaginationStep } from '@containex/portal-backend-dto';
    import { ref } from 'vue';
    import { DEFAULT_ROW_AMOUNT_OPTIONS_TABLE, DEFAULT_ROWS_PER_TABLE } from '@/constants';
    import { getLogger } from '@containex/logger';
    import SelectButton from 'primevue/selectbutton';
    import Checkbox from 'primevue/checkbox';
    import { Navigation } from '@/router/navigation';

    const { t } = useI18n();
    const { leads, amount } = useLeadQuery();
    const leadAction = useLeadAction();
    const { dateFormatter } = useDateFormatter();
    const logger = getLogger('LeadsView');

    const selectedMyLeadsFilter = ref(false);
    const selectedFilter = ref(LeadStateFilter.Open);
    const filterOptions = ref([
        { label: t('ACCOUNT.LEADS.LEAD_STATE.OPEN'), value: LeadStateFilter.Open },
        { label: t('ACCOUNT.LEADS.LEAD_STATE.DONE'), value: LeadStateFilter.Done },
    ]);

    const isLoading = ref(false);
    const paginatorCurrentRows = ref(DEFAULT_ROWS_PER_TABLE);
    const paginatorCurrentPage = ref(0);
    const paginatorPageLinkSize = ref(2);
    const paginatorCurrentStep = ref(PaginationStep.New);

    const task = useAsyncTask(async () => {
        await leadAction.fetchLeads(
            DEFAULT_ROWS_PER_TABLE,
            paginatorCurrentStep.value,
            selectedFilter.value,
            undefined
        );
    }).perform();

    const fetchLeads = useAsyncTask(async (signal, data: FetchPaginatedDataDto) => {
        logger.debug('LeadView', 'fetching leads for table');
        isLoading.value = true;
        await leadAction.fetchLeads(data.dataAmount, data.step, selectedFilter.value, selectedMyLeadsFilter.value);
        isLoading.value = false;
    }).drop();

    async function handlePaginatorChange(event: DataTablePageEvent): Promise<void> {
        if (paginatorCurrentPage.value === event.page && paginatorCurrentRows.value === event.rows) {
            return;
        }

        if (paginatorCurrentRows.value !== event.rows) {
            paginatorCurrentRows.value = event.rows;
            paginatorCurrentStep.value = PaginationStep.New;
            await fetchLeads.perform({
                dataAmount: event.rows,
                step: paginatorCurrentStep.value,
            });
            event.page = 0;
        } else {
            paginatorCurrentStep.value =
                paginatorCurrentPage.value < event.page ? PaginationStep.Next : PaginationStep.Previous;
            await fetchLeads.perform({
                dataAmount: event.rows,
                step: paginatorCurrentStep.value,
            });
        }

        paginatorCurrentPage.value = event.page;
        getPageLinkSize();
    }

    function getPageLinkSize(): void {
        const lastPage = Math.ceil(amount.value / paginatorCurrentRows.value) - 1;
        if (paginatorCurrentPage.value === 0 || paginatorCurrentPage.value === lastPage) {
            paginatorPageLinkSize.value = 2;
        } else {
            paginatorPageLinkSize.value = 3;
        }
    }

    async function reloadLeads(): Promise<void> {
        paginatorCurrentStep.value = PaginationStep.New;
        paginatorCurrentPage.value = 0;
        await fetchLeads.perform({
            dataAmount: paginatorCurrentRows.value,
            step: paginatorCurrentStep.value,
        });
        getPageLinkSize();
    }
</script>
<template>
    <div class="lead-page-container">
        <h2 class="text-2xl-bold-line-height-auto">{{ t('ACCOUNT.LEADS.TITLE') }}</h2>
        <div class="lead-filter">
            <SelectButton
                v-model="selectedFilter"
                option-label="label"
                option-value="value"
                :options="filterOptions"
                :allow-empty="false"
                aria-labelledby="basic"
                @change="reloadLeads()"
            />
            <div class="lead-filter-checkbox">
                <Checkbox v-model="selectedMyLeadsFilter" binary input-id="my-leads" @change="reloadLeads()" />
                <label for="my-leads">{{ t('ACCOUNT.LEADS.ASSIGNED_TO_ME') }}</label>
            </div>
        </div>

        <div v-if="task.isSuccessful">
            <DataTable
                v-if="task.isSuccessful && leads.length > 0"
                class="lead-table"
                lazy
                :value="leads"
                paginator
                :total-records="amount"
                :rows="paginatorCurrentRows"
                :rows-per-page-options="DEFAULT_ROW_AMOUNT_OPTIONS_TABLE"
                :loading="isLoading"
                :page-link-size="paginatorPageLinkSize"
                :first="paginatorCurrentPage * paginatorCurrentRows"
                :current-page-report-template="
                    t('ACCOUNT.PAGINATOR', {
                        first: '{first}',
                        last: '{last}',
                        totalRecords: '{totalRecords}',
                    })
                "
                paginator-template="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                @page="handlePaginatorChange"
            >
                <Column field="externalId" :header="t('ACCOUNT.LEADS.NUMBER')">
                    <template #body="slotProps">
                        <router-link
                            :to="{ name: Navigation.LeadDetails, params: { id: slotProps.data.id } }"
                            class="link"
                            style="display: block"
                            >{{ slotProps.data.externalId }}
                        </router-link>
                    </template>
                </Column>
                <Column field="date" :header="t('ACCOUNT.LEADS.DATE')">
                    <template #body="slotProps">
                        {{ dateFormatter.formatDateTwoDigits(parseISO(slotProps.data.date)) }}
                    </template>
                </Column>
                <Column field="internalState" :header="t('ACCOUNT.LEADS.STATE')">
                    <template #body="slotProps">
                        <LeadChip :state="slotProps.data.internalState" />
                    </template>
                </Column>
                <Column field="company" :header="t('ACCOUNT.LEADS.COMPANY')">
                    <template #body="slotProps">
                        {{ slotProps.data.prospect.company_name }}
                    </template>
                </Column>
                <Column field="type" :header="t('ACCOUNT.LEADS.TYPE')" />
                <Column field="state" :header="t('ACCOUNT.LEADS.ACTIVITY')" />
                <Column field="contact" :header="t('ACCOUNT.LEADS.ASSIGNED_TO')">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.users.length > 0">{{ slotProps.data.users.join(', ') }}</span>
                        <span v-else>-</span>
                    </template>
                </Column>
            </DataTable>

            <p v-else-if="task.isError || leads.length === 0">{{ t('ACCOUNT.LEADS.NO_LEADS') }}</p>
        </div>

        <LoadingSpinner v-else-if="task.isRunning" />
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .lead-filter {
        display: flex;
        gap: main.$spacing-6;
        align-items: center;
    }

    .lead-filter-checkbox {
        display: flex;
        gap: main.$spacing-3;
        align-items: center;
    }

    .lead-table {
        margin-top: main.$spacing-6;
    }

    .link {
        color: main.$ctx-primary-color;
        text-decoration: none;
    }

    .link:hover {
        color: main.$color-primary-darker;
        text-decoration: underline;
    }

    .lead-page-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: main.$spacing-6;
    }

    :deep(.p-selectbutton .p-button.p-highlight) {
        background: main.$color-background-lightblue;
        border-color: main.$color-border-darkgray;
        color: main.$ctx-primary-color;
    }
</style>
